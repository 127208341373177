import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { InterceptorService } from './services/interceptor/interceptor.service';
import { CommonHttpService } from './services/core/common-http.service';
import {CurrencyPipeModule} from './pipe/currency-pipe.module';
import { SuccessAppEVoucherComponent } from './content/success-app-e-voucher/success-app-e-voucher.component';

@NgModule({
  declarations: [
    AppComponent,
    SuccessAppEVoucherComponent,
  ],
  imports: [
    BrowserModule,
    CurrencyPipeModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      autoDismiss: true,
      preventDuplicates: true,
    }),
    HttpClientJsonpModule,
    NgxSpinnerModule,
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  providers: [CommonHttpService, { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
