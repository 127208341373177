import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'pages',
    pathMatch: 'full'
  },
  {
    path: 'pages',
    loadChildren: () => import('./content/pages/pages.module').then(m => m.PagesModule),
  },
  {
    path:'thank-you',
    loadChildren: () => import('./content/thank-you/thank-you.module').then(m=>m.ThankYouModule)
  },
  {
    path:'success-app',
    loadChildren: () => import('./content/success-app/success-app.module').then(m=>m.SuccessAppModule)
  },
  {
    path:'success-app-eVoucher',
    loadChildren: () => import(`./content/success-app-e-voucher/success-app-e-voucher.module`).then(m=>m.SuccessAppEVoucherModule)
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'|| 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
