<div class="thank">
    <div class="body">
        <img src="assets/images/invest.svg" alt="">
        <p class="success">Payment Successful!
        </p>
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-12 active_div">
            <div class="download_flex">
                <span class="acive-para mt-2">Payment Reference</span>
                <span><button class="btn download_btn"
                    (click)="downloadRecipt()"><img src="assets/images/download.svg" alt=""></button></span>
            </div>
            <div>
                <div class="row mt-4">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-6">
                        <p class="heading-details">Reference Number:</p>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-6">
                        <p class="heading-ans-1">{{trans_data?.ref_number}}</p>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-6">
                        <p class="heading-details">Digisolar Details:</p>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-6">
                        <p class="heading-ans">{{trans_data?.watts_purchased}} Wp</p>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-6">
                        <p class="heading-details">Project Name:</p>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-6">
                        <p class="heading-ans">{{trans_data?.invested_in?.project_name}}</p>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-6">
                        <p class="heading-details">Purchased By:</p>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-6">
                        <p class="heading-ans">{{trans_data?.invested_by?.full_name}}
                        <p>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-6">
                        <p class="heading-details">Purchased On:</p>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-6">
                        <p class="heading-ans">{{trans_data?.created_at | date:'d MMM, y, h:mm a'}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>