import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent,HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { fromEvent, Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SharedService } from '../core/shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  constructor(private spinner: NgxSpinnerService, private toastr: ToastrService, private router: Router , private sharedService : SharedService) {
    const offline = fromEvent(window, 'offline');
    offline.subscribe((res) => {
      this.toastr.error('You are offline.!');
      return false;
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (localStorage.getItem('thirdRockToken')) {
      this.spinner.show();
      const req = request.clone({
        headers: new HttpHeaders({
          Authorization: localStorage.getItem('thirdRockToken'),
          // 'Accept': 'application/json'
        })
      });
      return next.handle(req).pipe(tap(event => {
        if (event instanceof HttpResponse) {
          this.spinner.hide();
        }
      }),
        catchError((err: any) => {
          // console.log(err);
          this.spinner.hide();
          if (err.status === 0) {
            this.toastr.error('You are offline.!');
          } else {
            if (err.error.status === 401) {
              localStorage.clear();
              this.router.navigate(['']);
            }
            else if (err.status === 404) {
              this.toastr.error(err.error.error.message);
            }
            else if (err.status == 400) {
              // console.log(err);
              if (err.error.error.message) {
                this.toastr.error(err.error.error.message);
                if(err.error.error.message == "Your payment is not successful") {
                  setTimeout(() => {
                    this.router.navigate(['pages/home']);
                  }, 5000);
                }
              }
              else {
                this.toastr.error(err.error);
              }
            }
            else if (err.error.error.message !== 'Invalid page.') {
              this.toastr.error(err.error.error.message);
            }
          }
          return of(err);
        })
      );
    }
    else {
      this.spinner.show();
      const req = request.clone({
        headers: new HttpHeaders({
          // 'Accept': 'application/json'
        })
      });
      return next.handle(req).pipe(
        tap((event: HttpEvent<any>) => {
          let err_msg = '';
          this.spinner.show();
          if (event instanceof HttpResponse) {
            this.spinner.hide();
            if (event.body && event.body.data) {
              this.spinner.hide();
            }
            if (event.body && event.body.error) {
              this.spinner.hide();
            }
          }
        }),
        catchError((err: any) => {
          if (err instanceof HttpErrorResponse) {
            // console.log(err)
            if (err.status == 400) {
              this.spinner.hide();
              this.sharedService.setPayment('true');
              // console.log(err);
              if (err.error.error.message) {
                this.toastr.error(err.error.error.message);
                if(err.error.error.message == "Your payment is not successful") {
                  setTimeout(() => {
                    this.router.navigate(['pages/home']);
                  }, 5000);
                }
              }
              else {
                this.toastr.error(err.error.error);
              }
            }
            if (err.error.status == 401) {
              this.spinner.hide();
              localStorage.clear();
              this.router.navigate(['']);
            }
            if (err.error.status == 500) {
              this.toastr.error('Something went wrong! Please Try Again.');
              this.spinner.hide();
            }
            if (err.error.status == 0) {
              this.toastr.error("Please check your internet connection.");
              this.spinner.hide();
            }
            if (err.status == 0) {
              this.toastr.error("Please check your internet connection.");
              this.spinner.hide();
            }
          }
          return of(err);
        })
      );
    }
  }



}
