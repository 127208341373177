import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{


  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta) { }
    
    title = 'third-rock-web';
    ngOnInit() {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      ).subscribe(() => {
          var rt = this.getChild(this.activatedRoute)
          rt.data.subscribe(data => {
            // console.log(data);
            if(data.title){
              this.titleService.setTitle(data.title)
            }
            else{
              this.titleService.setTitle('Incept')
            }
            if (data.description) {
              this.metaService.updateTag({ name: 'description', content: data.description })
            } else {
              this.metaService.removeTag("name='description'")
            }          
          })
        })
    }

    
    getChild(activatedRoute: ActivatedRoute) {
      if (activatedRoute.firstChild) {
        return this.getChild(activatedRoute.firstChild);
      } else {
        return activatedRoute;
      }
   
    }
    onLoad() {
      window.scrollTo(0, 0);
    }
}
