import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from "rxjs/internal/Observable";
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CommonHttpService {

    BASE_URL: string = environment.base_Url;

    constructor(private http: HttpClient) { }

    get<T>(url: string): Observable<T> {
        return this.http.get<T>(this.BASE_URL + url);
    }

    post<T>(url: string, body: any): Observable<T> {
        return this.http.post<T>(this.BASE_URL + url, body);
    }

    put<T>(url: string, body: any): Observable<T> {
        return this.http.put<T>(this.BASE_URL + url, body);
    }

    delete<T>(url: string): Observable<T> {
        return this.http.delete<T>(this.BASE_URL + url);
    }

    patch<T>(url: string, body: any): Observable<T> {
        return this.http.patch<T>(this.BASE_URL + url, body);
    }

}
