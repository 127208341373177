import { Injectable } from '@angular/core';
import { CommonHttpService } from '../common-http.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private httpService: CommonHttpService) { }

  getpaymentId(body: any) {
    return this.httpService.post(`payments/create_payment`, body)
  }

  VerifySignature(data: any): any {
    return this.httpService.post(`payments/verify_signature`, data);
  }

  // get payment details after referral code
  getPaymentDetail(code, amount) {
    return this.httpService.get(`user/payment_details?refer_code=${code}&amount=${amount}`)
  }

  // view details on step 5 [payment reference data]
  getPaymentReference(id: any) {
    return this.httpService.get(`user/payment_reference/${id}`);
  }

  // download payment reciept
  downloadPaymentReceipt(id) {
    return this.httpService.get(`user/download_receipt/${id}?type=1`)
  }
  // download payment reciept
  downloadBookingReceipt(id) {
    return this.httpService.get(`user/download_receipt/${id}?type=2`)
  }

  // phonepay APi
  sendAmountValue(data: any): any {
    return this.httpService.post(`user/payment_api`, data);
  }

  //phonepay status
  getTransactionStatus(id:any){
    return this.httpService.get(`user/transaction_status?user_id=${id}`)
  }

  getVoucherStatus(id:any){
    return this.httpService.get(`user/voucher_status?user_id=${id}`)
  }

  bookNow(body:any){
    return this.httpService.post(`user/create_booking`,body);
  }

  bookingStatus(id:any){
    return this.httpService.get(`user/RetrieveBookedStatus/${id}`)
  }

  // PhonePe API to purchase E-voucher
  buyEvoucher(data:any){
    return this.httpService.post(`user/voucher_payment`,data);
  }
}
