import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CurrencyPipePipe} from './currency-pipe/currency-pipe.pipe'
import { CurrencyConvertPipe } from './currencyConvert/currency-convert.pipe';

@NgModule({
  declarations: [CurrencyPipePipe,CurrencyConvertPipe],
  exports: [ CurrencyPipePipe, CurrencyConvertPipe ],
  imports: [
    CommonModule
  ]
})
export class CurrencyPipeModule { }
