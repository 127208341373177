import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentService } from '../../services/core/payment/payment.service';
import { SharedService } from '../../services/core/shared/shared.service';

@Component({
  selector: 'app-success-app-e-voucher',
  templateUrl: './success-app-e-voucher.component.html',
  styleUrls: ['./success-app-e-voucher.component.scss']
})
export class SuccessAppEVoucherComponent implements OnInit {

  trans_id: any = '';
  trans_data: any = [];

  constructor(private route:ActivatedRoute,private service: PaymentService,private sharedService: SharedService) { }

  ngOnInit(): void {
    this.getFromParams();
  }


 getFromParams(): void {
  this.route.queryParams
    .subscribe(params => {
      this.trans_id = params.id;
    });
  this.getPaymentStatus(this.trans_id); // call once in starting
}
getPaymentStatus(id: any) {
  this.service.getPaymentReference(id).subscribe((res:any)=>{
    if (res.status === 200) {
      this.trans_data = res.data;
    }
  });

}

downloadRecipt() {
  this.service.downloadPaymentReceipt(this.trans_id).subscribe((res: any) => {
    window.open(res.data.url, '_self');
  })

}
}
