import { CdkStepper } from '@angular/cdk/stepper';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private completedStepper: boolean = false;

  private _data = new BehaviorSubject<string>('');

  private _id = new BehaviorSubject<string>('');

  private _payment = new BehaviorSubject<string>('false');

  private _hide = new BehaviorSubject<string>('true');

  private _graphID = new BehaviorSubject<number>(0);

  private _camp = new BehaviorSubject<boolean>(false);

  private dataSource = new Subject<any>();
  data$ = this.dataSource.asObservable();

  private dataMailSource = new Subject<any>();
  mailData$ = this.dataMailSource.asObservable();

  private projectName = new Subject<any>();
  projectName$ = this.projectName.asObservable();

  private projectName2 = new Subject<any>();
  projectName2$ = this.projectName2.asObservable();

  private sideOption = new Subject<any>();
  sideOption$ = this.sideOption.asObservable();

  private onboardingData = new Subject<any>();
  onboardingData$ = this.onboardingData.asObservable();

  private closeOption = new Subject<any>();
  closeOption$ = this.closeOption.asObservable();

  private referenceData = new Subject<any>();
  referenceData$ = this.referenceData.asObservable();

  public goToStep2 = false;
  public stepper: CdkStepper;

  public goToStep3 = false;
  
  private stepperSubject = new Subject<any>();

  public stepper$ = this.stepperSubject.asObservable();

  public updateStepper(value: any) {
    this.stepperSubject.next(value);
  }
  //new changes

  updateData(data: any) {
    this.dataSource.next(data);
  }
  updateMailData(data: any) {
    this.dataMailSource.next(data);
  }
  showData(data: any) {
    this.projectName.next(data);
  }

  showOnbaordingData(data: any) {
    this.onboardingData.next(data);
  }
  showData2(data: any) {
    this.projectName2.next(data);
  }

  showSide(data: any) {
    this.sideOption.next(data);
  }

  closeSide(data: any) {
    this.closeOption.next(data);
  }

   // stepper function
   setStepper(stepper: CdkStepper) {
    this.stepper = stepper;
  }

  next() {
    this.stepper.next();
    window.scroll(0, 0);
  }

  //completed
  setCompletedStepper(value: boolean): void {
    this.completedStepper = value;
  }

  getCompletedStepper(): boolean {
    return this.completedStepper;
  }

  get dataa$(): Observable<string> {
    return this._data.asObservable();
  }
   //
  setData(value: string): void {
    this._data.next(value);
  }

  setTransaction(value:string):void{
    this._id.next(value);
  }
  get id$(): Observable<string>{
    return this._id.asObservable();
  }

  setPayment(value:any){
    this._payment.next(value);
  }

  get payment$(): Observable<string>{
    return this._payment.asObservable();
  }

  setHide(value:any){
    this._hide.next(value);
  }

  get hide$(): Observable<string>{
    return this._hide.asObservable();
  }

  setCamp(value:any){
    this._camp.next(value);
  }
  get capm$(): Observable<boolean>{
    return this._camp.asObservable();
  }
  showreferenceData(data: any) {
    this.referenceData.next(data);
  }
  

  // graph
  setGraph(value:any){
    this._graphID.next(value);
  }
  get graphID$(): Observable<number>{
    return this._graphID.asObservable();
  }
}
